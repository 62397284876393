import React from "react"
import './Hamburger.scss'

export const Hamburger = ({ menuToggle, handleToggleMenu}) => (
  <button className={`hamburger ${menuToggle && 'open'}`} onClick={handleToggleMenu}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
)
