import { Link } from "gatsby"
import React from "react"
import CookieConsent from "react-cookie-consent";

export const Footer = props => (
  <footer className='container'>
    <CookieConsent
      disableStyles
      location='none'
      buttonText='Accept'
      declineButtonText='Decline'
      cookieName='gatsby-gdpr-google-analytics'
      containerClasses='cookie-consent'
      style={{ bottom: '6.25rem' }}
    >
      This site uses cookies to offer you a better browsing experience. Find out more <Link to='/cookie-policy'>here</Link>.
    </CookieConsent>    
    <a href='tel:+44 (0)7884 233131' className='tel'>+44 (0)7884 233131</a>
    <div className='service'>
      Copyright © 2021 Montana Concept
      <Link to='/cookie-policy' className='separator'>Cookie Policy</Link>
      <Link to='/policy'>Website Policies</Link>
    </div>
  </footer>
)