// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-holding-jsx": () => import("./../../../src/pages/holding.jsx" /* webpackChunkName: "component---src-pages-holding-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-policy-jsx": () => import("./../../../src/pages/policy.jsx" /* webpackChunkName: "component---src-pages-policy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-who-jsx": () => import("./../../../src/pages/who.jsx" /* webpackChunkName: "component---src-pages-who-jsx" */),
  "component---src-pages-why-jsx": () => import("./../../../src/pages/why.jsx" /* webpackChunkName: "component---src-pages-why-jsx" */)
}

