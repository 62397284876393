import React from "react"
import { Link } from "gatsby"
import logo from "../../images/logo.png"
import { Hamburger } from "../Hamburger/Hamburger"
import { MainMenu } from "../MainMenu/MainMenu"

export const Header = ({ menuToggle, handleToggleMenu }) => (
  <header className='container'>
    <Link to='/'>
      <img src={logo} alt='Montana Concept' className='logo' />
    </Link>
    <MainMenu {...{menuToggle}} />
    <Hamburger {...{menuToggle, handleToggleMenu}} />
  </header>
)
