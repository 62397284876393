import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const MainMenu = ({ menuToggle }) => {
  const NAV_LINKS = [
    { name: 'About', link: '/about' },
    { name: 'Who', link: '/who' },
    { name: 'Why', link: '/why' },
    { name: 'Services', link: '/services' },
    { name: 'Hello', link: '/contact' },
  ]
  return (
    <nav className={menuToggle ? 'open' : 'undefined'}>
      { NAV_LINKS.map(({name, link, direction}) => (
        <AniLink
          key={name}
          to={link}
          swipe
          direction={direction}
          activeClassName='active'
        >
          {name}
        </AniLink>
      ))}
    </nav>
  )
}