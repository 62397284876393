import React, { useEffect, useState } from "react"
import { Header } from "../components/Header/Header"
import { Footer } from "../components/Footer/Footer"
// import IndexPage from "../pages/index"

const Layout = (props) => {
  const [menuToggle, setMenuToggle] = useState(false)
  useEffect(() => {
    setMenuToggle(false)
  }, [props.location.pathname])

  const handleToggleMenu = (e) => {
    e.preventDefault()
    setMenuToggle(!menuToggle)
  }

  // if(props.location.pathname === '/') {
  //   return <IndexPage />
  // }
  return (
    <>
      <Header {...{menuToggle, handleToggleMenu}} />
      { props.children }
      <Footer />
    </>
  )
}

export default Layout